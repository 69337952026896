var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: _vm.key,
      ref: "g-grid-container",
      staticClass: "g-grid-container",
      style: {
        left: `${_vm.rowLabelWidth}px`,
        width: `${_vm.timeCount * _vm.gridSize}px`,
      },
    },
    _vm._l(_vm.allChildPoints, function (childPoint, index) {
      return _c("div", {
        key: index,
        ref:
          childPoint === _vm.getActiveHour
            ? "activeHour"
            : `g-grid-line-${childPoint}`,
        refInFor: true,
        class: [
          "g-grid-line",
          { "g-grid-line-last": index === _vm.allChildPoints.length - 1 },
          {
            "g-grid-line-highlighted":
              (_vm.precision === "day" &&
                _vm.highlightedHours.includes(childPoint) &&
                index < 24) ||
              (_vm.precision === "month" &&
                _vm.highlightedDays.includes(childPoint)),
          },
          { "g-grid-line-highlighted": childPoint === _vm.getActiveHour },
        ],
        style: { width: `${_vm.gridSize}px` },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }