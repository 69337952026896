<template>
  <div
    ref="g-grid-container"
    :key="key"
    class="g-grid-container"
    :style="{ left: `${rowLabelWidth}px`, width: `${timeCount * gridSize}px` }"
  >
    <div
      v-for="(childPoint, index) in allChildPoints"
      :ref="
        childPoint === getActiveHour
          ? 'activeHour'
          : `g-grid-line-${childPoint}`
      "
      :key="index"
      :class="[
        'g-grid-line',
        { 'g-grid-line-last': index === allChildPoints.length - 1 },
        {
          'g-grid-line-highlighted':
            (precision === 'day' &&
              highlightedHours.includes(childPoint) &&
              index < 24) ||
            (precision === 'month' && highlightedDays.includes(childPoint)),
        },
        { 'g-grid-line-highlighted': childPoint === getActiveHour },
      ]"
      :style="{ width: `${gridSize}px` }"
    />
  </div>
</template>

<script>
import { DATE_FORMAT_WITHOUT_MINUTES } from '@/constants/date'
import { getTime, now } from '@/core'
import moment from '@/core/date'

export default {
  name: 'GGanttGrid',

  props: {
    chartStart: { type: String },
    chartEnd: { type: String },
    rowLabelWidth: { type: Number },
    highlightedHours: { type: Array, default: () => [] },
    highlightedDays: { type: Array, default: () => [] },
    precision: { type: String },
    timeCount: { type: Number },
    gridSize: { type: Number },
  },
  data() {
    return {
      interval: null,
      key: 0,
    }
  },
  computed: {
    allChildPoints() {
      let start = moment(this.chartStart)
      let end = moment(this.chartEnd)
      let res = []

      while (start.isBefore(end)) {
        switch (this.precision) {
          case 'day':
            res.push(getTime(start, DATE_FORMAT_WITHOUT_MINUTES))
            // res.push(start.hour())
            start.add(1, 'hour')
            break
          case 'month':
            res.push(start.format('YYYY-MM-DD'))
            start.add(1, 'day').hour(23)
            break
        }
      }

      return res
    },
    getActiveHour() {
      return getTime(now(), DATE_FORMAT_WITHOUT_MINUTES)
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.key++
    }, 60000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
}
</script>
